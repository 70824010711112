import React, { useEffect } from 'react';
import Layout from '../components/Layout';
import RonakHero from '../components/RonakPipaliya/RonakHero';

import allApiData from "../api";
import PortfolioCom from '../components/Home/PortfolioCom';
import allData from '../allData';
import IndustriesCom from '../components/Home/IndustriesCom';

const ronakExpertisesData = allData.vimalExpertisesData;

const userData = {
    name: "Vimal Tarsariya",
    designation: "Project Manager",
    image: '../assets/img/ronak-pipaliya/vimal-tarsariya.png',
    tag: `vimal-tarsariya`,
    phones: ['(+91) 8469401090'],
    // emails: ['vimal@vasundhara.io', 'vimal.vasundhara@gmail.com'],
    emails: ['vimal@vasundhara.io'],
    linkedin: 'https://in.linkedin.com/in/vimal-tarsariya-8b9456121',
    skype: 'https://join.skype.com/invite/ThIk9HM34qQg',
    behance: `https://www.behance.net/vasundharainfotech`,
    whatsapp: `https://wa.me/+918469401090`,
    description: [
        `Mr Vimal Tarsariya is a Head of Department of Web & Mobile App at Vasundhara Infotech, renowned for driving the company's growth through effective leadership.`,
        `He is committed to the mission of developing interactive web solutions with user-friendly UI and visually captivating interfaces, elevating the user experience to new heights.`,
        `A proactive communicator who ensures his team is well-informed about project progress and fosters a culture of continuous learning and improvement.`,
        `He excels in communication with clients and his team members, ensuring that projects are completed on schedule and within established deadlines. `,
        `He excels at business dynamics, consistently positively impacting company projects through strategic insights and understanding.`,
        `Demonstrates exemplary communication skills in interactions with clients and team members at every project stage, resulting in the timely and successful completion of projects within established deadlines.`,
        `Adaptable and responsive, he effectively aligns with changing strategic objectives, offering transparency and clarity in communication.`,
        `He diligently monitors the web and mobile app development process, offering swift assistance in troubleshooting issues encountered during development and testing.`,
    ]
}

function VimalTarsariya() {
    
    const tabNameData = ['App Development', 'Website Development'];
    useEffect(() => {
        allApiData.aosinit();
    }, []);

    return (
        <Layout footernone={false} padding={true} popup={true}>
            <div className='main-ronak'>
                <div className='ronak-hero'>
                    <RonakHero userData={userData} />
                </div>

                <div className="ronak-portfolio">
                    <PortfolioCom
                        heading="Wall of Pride"
                        isTabOn={true}
                        isImageComp={true}
                        isViewBtn={true}
                        // viewurl={`portfolio?type=website_development`}
                        viewurl={`portfolio`}
                        viewButton={'View the entire portfolio'}
                        tabData={tabNameData}
                        typeWise='app_type'
                        isPagination={false}
                        //portfolios={}
                    />
                </div>

                <div className="ronak-expertises">
                    <IndustriesCom h1="Expertises" industriesData={ronakExpertisesData} buttonOn={false} bg={true} upperCase={true} />
                </div>
            </div>
        </Layout>
    )
}

export default VimalTarsariya